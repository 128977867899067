import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTable1 } from '../block-table-1.constants.js';

export interface BlockTable1HeadProps {
    children?: ComponentChildren;
}

export const BlockTable1Head: FunctionalComponent<BlockTable1HeadProps> = ({ children }) => (
    <thead className={cnBlockTable1('head')}>{children}</thead>
);
