import type { Ref } from 'preact/hooks';
import { useEffect, useState } from 'preact/hooks';

export const useIsScrollable = (ref: Ref<HTMLElement>) => {
    const [scrollable, setScrollable] = useState(false);

    useEffect(() => {
        const updateScrollable = () => {
            if (!ref.current) {
                return;
            }

            setScrollable(ref.current.scrollWidth > ref.current.clientWidth);
        };

        updateScrollable();

        if (!ref.current) {
            return;
        }

        const observer = new ResizeObserver(() => {
            updateScrollable();
        });

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return scrollable;
};
