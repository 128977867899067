import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTable1 } from '../block-table-1.constants.js';

export interface BlockTable1RowProps {
    children?: ComponentChildren;
}

export const BlockTable1Row: FunctionalComponent<BlockTable1RowProps> = ({ children }) => (
    <tr className={cnBlockTable1('row')}>{children}</tr>
);
