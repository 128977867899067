import check from './svgs/check.svg';
import cross from './svgs/cross.svg';
import type { FunctionalComponent } from 'preact';

import { cnBlockTable1 } from '../block-table-1.constants.js';

export interface BlockTable1IconProps {
    type: string | undefined;
}

export const BlockTable1Icon: FunctionalComponent<BlockTable1IconProps> = ({ type }) => {
    switch (type) {
        case 'check':
            return <img className={cnBlockTable1('icon')} src={check.src} />;

        case 'cross':
            return <img className={cnBlockTable1('icon')} src={cross.src} />;

        default:
            return null;
    }
};
