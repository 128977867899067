import type { ClassNameFormatter } from '@bem-react/classname';
import { useEffect } from 'preact/hooks';

import { cnBlockCardsProItem } from 'src/features/blocks/block-cards-pro-item/block-cards-pro-item.constants.js';

import { cnBlockCardsPro } from '../block-cards-pro.constants.js';

const makeSelectorFromCn = (fn: ClassNameFormatter, element?: string) => {
    const classes = element ? fn(element) : fn();

    return '.' + classes.split(' ').join('.');
};

const WATCH_ELEMENTS = ['name', 'profession', 'description', 'years', 'companies'];

export const useEqualHeights = () => {
    useEffect(() => {
        const adjustHeights = () => {
            const container = document.querySelector(makeSelectorFromCn(cnBlockCardsPro));

            if (!container) {
                return;
            }

            for (const elementSelector of WATCH_ELEMENTS) {
                const elements = container.querySelectorAll(makeSelectorFromCn(cnBlockCardsProItem, elementSelector));

                for (const element of elements) {
                    (element as HTMLElement).style.height = '';
                }

                let maxHeight = -1;

                for (const element of elements) {
                    const { height } = (element as HTMLElement).getBoundingClientRect();

                    if (height > maxHeight) {
                        maxHeight = height;
                    }
                }

                for (const element of elements) {
                    (element as HTMLElement).style.height = `${maxHeight}px`;
                }
            }
        };

        adjustHeights();
    }, []);
};
