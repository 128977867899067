import type { ComponentChildren } from 'preact';
import { forwardRef } from 'preact/compat';

import { cnBlockTable1 } from '../block-table-1.constants.js';

import './block-table-1__scrollable.css';

export interface BlockTable1ScrollableProps {
    children?: ComponentChildren;
}

export const BlockTable1Scrollable = forwardRef<HTMLDivElement, BlockTable1ScrollableProps>(({ children }, ref) => (
    <div ref={ref} className={cnBlockTable1('scrollable')}>
        {children}
    </div>
));
