import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTable1 } from '../block-table-1.constants.js';

export interface BlockTable1BodyProps {
    children?: ComponentChildren;
}

export const BlockTable1Body: FunctionalComponent<BlockTable1BodyProps> = ({ children }) => (
    <tbody className={cnBlockTable1('body')}>{children}</tbody>
);
