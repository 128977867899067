import { type FunctionalComponent } from 'preact';
import { useCallback, useRef } from 'preact/hooks';

import { ArrowButton } from 'src/shared/components/arrow-button/arrow-button.js';
import { BlockWrapper } from 'src/shared/components/block-wrapper/block-wrapper.js';

import { BlockTable1Body } from './__body/block-table-1__body.js';
import { BlockTable1Cell } from './__cell/block-table-1__cell.js';
import { BlockTable1Head } from './__head/block-table-1__head.js';
import { BlockTable1Row } from './__row/block-table-1__row.js';
import { BlockTable1Scrollable } from './__scrollable/block-table-1__scrollable.js';
import { BlockTable1Table } from './__table/block-table-1__table.js';
import { cnBlockTable1 } from './block-table-1.constants.js';
import { useIsScrollable } from './block-table-1.hooks/use-is-scrollable.js';

import './__arrows/block-table-1__arrows.css';

type TableRow = {
    label: string;
    type: 'text' | 'icon';
    values: string[];
};

type BlockAttributes = {
    table: TableRow[];
    cellTextColor: 'primary' | 'secondary';
    selectedColumn: string;
    selectedColumnColor: string;
};

export interface BlockTable1Props extends BlockAttributes {}

const colorsMap = {
    green: '#6CC989',
    orange: '#FF976B',
    blue: '#87BEFA',
    red: '#F89E93',
    yellow: '#FFD078',
    purple: '#BFA4FF',
};

export const BlockTable1: FunctionalComponent<BlockTable1Props> = ({
    table,
    cellTextColor,
    selectedColumnColor,
    selectedColumn,
}) => {
    const [headerRow, ...rest] = table;
    const selectedColumnNumber = Number(selectedColumn);
    const selectedColor =
        !Number.isNaN(selectedColumnNumber) && selectedColumnNumber > 0 && selectedColumnColor in colorsMap
            ? colorsMap[selectedColumnColor as keyof typeof colorsMap]
            : undefined;

    const containerRef = useRef<HTMLDivElement>(null);
    const scrollable = useIsScrollable(containerRef);

    const onArrowLeftClick = useCallback(() => {
        containerRef.current?.scrollBy({ left: -140, behavior: 'smooth' });
    }, []);

    const onArrowRightClick = useCallback(() => {
        containerRef.current?.scrollBy({ left: 140, behavior: 'smooth' });
    }, []);

    if (!headerRow) {
        return null;
    }

    const renderCell = (row: TableRow, rowIndex: number) => (cell: string, cellIndex: number) => {
        let roundedCorner: 'tl' | 'tr' | 'bl' | 'br' | undefined = undefined;

        if (cellIndex === row.values.length - 1) {
            if (rowIndex === 0) {
                roundedCorner = 'tr';
            }

            if (rowIndex === rest.length - 1) {
                roundedCorner = 'br';
            }
        }

        return (
            <BlockTable1Cell
                type={row.type}
                color={cellIndex + 1 === selectedColumnNumber ? selectedColor : undefined}
                white
                secondary={cellTextColor === 'secondary'}
                key={cellIndex}
                roundedCorner={roundedCorner}
            >
                {cell}
            </BlockTable1Cell>
        );
    };

    const renderRow = (row: TableRow, rowIndex: number) => {
        let roundedCorner: 'tl' | 'tr' | 'bl' | 'br' | undefined = undefined;

        if (rowIndex === 0) {
            roundedCorner = 'tl';
        }

        if (rowIndex === rest.length - 1) {
            roundedCorner = 'bl';
        }

        return (
            <BlockTable1Row key={rowIndex}>
                <BlockTable1Cell roundedCorner={roundedCorner} sticky white bold alignedLeft>
                    {row.label}
                </BlockTable1Cell>
                {row.values.map(renderCell(row, rowIndex))}
            </BlockTable1Row>
        );
    };

    return (
        <BlockWrapper className={cnBlockTable1({})}>
            <BlockTable1Scrollable ref={containerRef}>
                <BlockTable1Table>
                    <BlockTable1Head>
                        <BlockTable1Row>
                            <BlockTable1Cell header bold sticky alignedLeft>
                                {headerRow.label}
                            </BlockTable1Cell>
                            {headerRow.values.map((cell, index) => (
                                <BlockTable1Cell
                                    color={index + 1 === selectedColumnNumber ? selectedColor : undefined}
                                    header
                                    type={headerRow.type}
                                    bold
                                    key={index}
                                    topRounded
                                >
                                    {cell}
                                </BlockTable1Cell>
                            ))}
                        </BlockTable1Row>
                    </BlockTable1Head>
                    <BlockTable1Body>{rest.map(renderRow)}</BlockTable1Body>
                </BlockTable1Table>
            </BlockTable1Scrollable>
            {scrollable && (
                <div className={cnBlockTable1('arrows')}>
                    <ArrowButton onPointerDown={onArrowLeftClick} />
                    <ArrowButton onPointerDown={onArrowRightClick} right />
                </div>
            )}
        </BlockWrapper>
    );
};
