import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTable1 } from '../block-table-1.constants.js';

import './block-table-1__table.css';

export interface BlockTable1TableProps {
    children?: ComponentChildren;
}

export const BlockTable1Table: FunctionalComponent<BlockTable1TableProps> = ({ children }) => (
    <table className={cnBlockTable1('table')}>{children}</table>
);
