import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTable1 } from '../block-table-1.constants.js';

import './block-table-1__cell-child.css';

export interface BlockTable1CellChildProps {
    children?: ComponentChildren;
    secondary?: boolean;
    bold?: boolean;
    alignedLeft?: boolean;
    white?: boolean;
    color?: string;
    topRounded?: boolean;
    roundedCorner?: 'tl' | 'tr' | 'bl' | 'br';
}

export const BlockTable1CellChild: FunctionalComponent<BlockTable1CellChildProps> = ({
    children,
    secondary,
    bold,
    alignedLeft,
    white,
    color,
    topRounded,
    roundedCorner,
}) => (
    <div
        style={{ backgroundColor: color }}
        className={cnBlockTable1('cell-child', {
            'top-rounded': topRounded,
            'rounded-corner': roundedCorner,
            'aligned-left': alignedLeft,
            secondary,
            bold,
            white,
        })}
    >
        {children}
    </div>
);
