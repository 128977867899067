import type { FunctionalComponent } from 'preact';

import { BlockTable1CellChild, type BlockTable1CellChildProps } from '../__cell-child/block-table-1__cell-child.js';
import { BlockTable1Icon } from '../__icon/block-table-1__icon.js';
import { cnBlockTable1 } from '../block-table-1.constants.js';

import './block-table-1__cell.css';

export interface BlockTable1CellProps extends BlockTable1CellChildProps {
    children?: string;
    sticky?: boolean;
    type?: 'icon' | 'text';
    header?: boolean;
}

export const BlockTable1Cell: FunctionalComponent<BlockTable1CellProps> = ({
    children,
    type,
    sticky,
    header,
    ...props
}) => {
    const Tag = header ? 'th' : 'td';

    return (
        <Tag className={cnBlockTable1('cell', { sticky })}>
            <BlockTable1CellChild {...props}>
                {type === 'icon' ? <BlockTable1Icon type={children} /> : children}
            </BlockTable1CellChild>
        </Tag>
    );
};
